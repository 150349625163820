import AddressPicker from '@/components/Address/AddressPicker.vue';
import Autocomplete from '@/components/Autocomplete.vue';
import PicturePicker from '@/components/PicturePicker.vue';
import DatePicker from '@/components/DatePicker.vue';
import Facility from '@/components/Facility.vue';
import Field from '@/components/Field.vue';
import Gender from '@/components/Gender.vue';
import MySwitch from '@/components/iSwitch.vue';
import Phone from '@/components/Phone/PhonePicker.vue';
import saveButton from '../saveButton.vue';
import { confirm, toast } from '@/utils/dialog';
import RegexValidations from '@/utils/RegexValidations';
import { clone, difference, filter, innerJoin, propEq } from 'ramda';
export default {
  components: {
    AddressPicker,
    Autocomplete,
    PicturePicker,
    DatePicker,
    Facility,
    Field,
    Gender,
    MySwitch,
    Phone,
    saveButton
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', [
      'menu.dat',
      this.paramsEmployeeId ? 'employee.edit' : 'employee.new',
      'global.general'
    ]);
    if (this.oEmployee) {
      this.getRelatedEmployees();
    }
  },
  data() {
    const oEmployee = {
      user_type_id: null,
      user_type_name: '',
      ...this.employee,
      changed_user_type: false
    };
    return {
      companyRoles: [],
      loading: false,
      oEmployee,
      relatedEmployees: {
        prevIds: [],
        isDispatcherLoaded: false,
        isDriversLoaded: false,
        selected: []
      },
      RegexValidations
    };
  },
  computed: {
    maxDate() {
      return this.$moment().add(5, 'years').toDate();
    },
    minDate() {
      return this.$moment().toDate();
    },
    paramsEmployeeId() {
      return this.$route.params.employeeId;
    },
    currentUserType() {
      return filter(propEq('id', this.oEmployee?.user_type_id))(this.companyRoles)[0];
    },

    isDispatcher() {
      return this.oEmployee.user_type_name === 'Dispatcher';
    },
    isDriver() {
      return this.oEmployee.user_type_name === 'Driver';
    },
    filteredIds() {
      return this.relatedEmployees.selected.map((s) => s.user_id);
    },
    hasRelatedEmployees() {
      return this.isDriver || this.isDispatcher;
    }
  },
  methods: {
    onSelectRelatedEmployee(selected) {
      if (!selected) return;
      this.relatedEmployees.selected.push(selected);
    },
    prepareData() {
      const { oEmployee: E } = this;
      return {
        audible_alert_for_driver_messaging: E.audible_alert_for_driver_messaging,
        driver_license_expiration_date: this.isDriver ? E.driver_license_expiration_date : null,
        driver_license_number: this.isDriver ? E.driver_license_number : null,
        driver_time_keeping_enabled: E.driver_time_keeping_enabled,
        email: E.email,
        first_name: E.first_name,
        gender: E.gender,
        hide_charge_info: E.hide_charge_info,
        last_name: E.last_name,
        low_schedule_edit: E.low_schedule_edit,
        middle_name: E.middle_name,
        mobile_phone_number: E.mobile_phone_number,
        non_admin_access_history: E.non_admin_access_history,
        social_security_number: E.social_security_number,
        //time_zone: E.time_zone,
        user_type_id: E.user_type_id
      };
    },
    async getRelatedEmployees() {
      this.loadRelatedEmployees();
      const { user_id, dispatcher_user_id } = this.oEmployee;
      if (this.hasRelatedEmployees) {
        const { data } = await this.Api.get(`/users/${user_id}/notify_to_user`);
        if (this.isDispatcher) {
          const drivers = data.map((driver) => ({
            full_name: driver.driver_full_name,
            user_id: driver.driver_user_id
          }));
          this.relatedEmployees.selected = drivers;
          this.relatedEmployees.prevIds = clone(drivers).map((d) => d.user_id);
        } else if (dispatcher_user_id) {
          this.relatedEmployees.selected = [{ user_id: dispatcher_user_id }];
          this.relatedEmployees.prevIds = [dispatcher_user_id];
        }
      }
    },
    onSelectRole(id) {
      if ((id, this.oEmployee?.user_type_id === id)) return;
      this.oEmployee.changed_user_type = true;
      this.oEmployee.user_type_id = id;
      this.oEmployee.user_type_name = this.currentUserType?.name;
    },
    async confirmSaving() {
      const { id } = this.oEmployee;
      const pData = this.prepareData();
      const method = id ? 'put' : 'post';
      const url = `/employees${id ? `/${id}` : ''}`;
      if (id && this.oEmployee.changed_user_type) {
        const result = await new Promise((resolve) => {
          confirm({
            message: this.$t('confirms.changeUserType'),
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('confirm.yes'),
            onConfirm: async () => {
              const result = await this.saveEmployee(method, url, pData);
              this.oEmployee.changed_user_type = false;
              if (result) resolve(result);
              else resolve(false);
            },
            onCancel: () => resolve(false)
          });
        });
        return result;
      } else {
        return await this.saveEmployee(method, url, pData);
      }
    },
    async saveEmployee(method, url, pData) {
      try {
        return await this.Api[method](url, pData);
      } catch (error) {
        console.error(error);
      }
    },
    async savePicture() {
      const fileName = `${this.oEmployee.id}.png`;
      await this.$refs.picturePicker.save({ fileName });
    },
    loadRelatedEmployees() {
      if (this.isDispatcher) this.relatedEmployees.isDriversLoaded = true;
      if (this.isDriver) this.relatedEmployees.isDispatcherLoaded = true;
    },
    async prepareRelatedEmployees() {
      const { selected, prevIds } = this.relatedEmployees;
      const selectedIds = selected.map(({ user_id }) => user_id);
      const removeIds = prevIds.filter((id) => selectedIds.every((user_id) => id != user_id));
      const registerIds = selectedIds.filter((user_id) => prevIds.indexOf(user_id) < 0);
      try {
        const saveResult = await this.saveRelatedEmployees(registerIds);
        if (saveResult) {
          await this.removeRelatedEmployees(removeIds);
          this.relatedEmployees.prevIds = difference(selectedIds, removeIds);
        } else throw new Error('--Override rejected--');
      } catch (err) {
        throw new Error(err);
      }
    },
    async removeRelatedEmployees(notified_user_id) {
      if (!notified_user_id?.length) return;
      try {
        await this.Api.delete(`/users/${this.oEmployee.user_id}/notify_to_user`, {
          notified_user_id
        });
        this.relatedEmployees.prevIds = [];
      } catch (err) {
        throw new Error(err);
      }
    },
    async confirmRelatedEmployeesOverride(ids) {
      const message = this.isDispatcher ? 'relatedDrivers' : 'relatedDispatcher';
      const employeesNames = innerJoin(
        (record, id) => record.id === id,
        this.relatedEmployees.selected,
        ids
      )
        .map((e) => `<strong>*</strong> ${e.full_name}`)
        .join('<br/>');
      const result = await new Promise((resolve) => {
        confirm({
          message: `${this.$t(`confirms.${message}`)}<br/>${employeesNames}`,
          cancelText: this.$t('confirm.no'),
          confirmText: this.$t('confirm.yes'),
          onConfirm: async () => {
            const result = await this.saveRelatedEmployees(ids, true);
            if (result) resolve(true);
            else resolve(false);
          },
          onCancel: () => resolve(false)
        });
      });
      return result;
    },
    async saveRelatedEmployees(selectedIds, isOverride = false) {
      if (!selectedIds?.length) return true;
      const user = this.isDispatcher ? this.oEmployee.user_id : selectedIds[0];
      const notified_user_id = this.isDispatcher ? selectedIds : [this.oEmployee.user_id];
      try {
        await this.Api.post(`/users/${user}/notify_to_user${isOverride ? '/override' : ''}`, {
          notified_user_id
        });
        return true;
      } catch (err) {
        if (err.data.status_code === 'us017') {
          const ids = this.isDispatcher ? err.data.data.driver_user_id : selectedIds;
          const result = await this.confirmRelatedEmployeesOverride(ids);
          return result;
        } else throw new Error(err);
      }
    },
    onRemoveRelatedDriver(user_id) {
      const selected = this.relatedEmployees.selected;
      const result = selected.filter((s) => s.user_id !== user_id);
      this.relatedEmployees.selected = result;
    },
    async onSave() {
      if (this.validate()) {
        this.loading = true;
        try {
          const { data } = await this.confirmSaving();
          if (data) {
            this.oEmployee.id = data.id;
            data.user_id && (this.oEmployee.user_id = data.user_id);
            await this.savePicture();
            await this.prepareRelatedEmployees();
            this.oEmployee.changed_user_type = false;
            toast('success', this.$t('messages.saved'), 5000);
            this.$emit('change', this.oEmployee);
            this.$router.replace({
              path: `/data/employees/${this.oEmployee.user_id}`
            });
          }
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator;
    }
  },
  watch: {
    employee(employee) {
      this.oEmployee = employee;
      this.getRelatedEmployees();
    },
    'oEmployee.user_type_id'(user_type_id) {
      if (!user_type_id) return;
      this.loadRelatedEmployees();
      this.relatedEmployees.selected = [];
    },
    '$route.path'(value) {
      if (value === '/data/employees/add') {
        this.$store.dispatch('setBreadcumbs', ['menu.dat', 'employee.new', 'global.general']);
      } else {
        this.$store.dispatch('setBreadcumbs', ['menu.dat', 'employee.edit', 'global.general']);
      }
    }
  },
  props: {
    employee: {
      type: Object,
      required: true
    }
  }
};
